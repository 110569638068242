import React, { useState } from 'react';
import { TouchableOpacity, Text, TextInput, View, StyleSheet } from 'react-native';

import { useAuth } from './hooks/useAuth';

function AuthForm() {
  const { resetPassword, signUp, signIn } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(false);

  const handleSubmit = async () => {
    if (isLogin) {
      try {
        await signIn(email, password);
      } catch (error) {
        alert('Error logging in');
        console.error(error);
      }
    } else {
      try {
        await signUp(email, password);
      } catch (error) {
        alert('Error signing up');
        console.error(error);
      }
    }
  };

  const handleResetPassword = async () => {
    let promptEmail;
    if (!email) {
      promptEmail = window.prompt('Please provide your email:');
    }
    try {
      await resetPassword(email || promptEmail);
      alert('Reset password link has been sent to your email address.');
    } catch (error) {
      alert('There was an error attempting to send a reset password link.');
      console.error(error);
    }
  };

  const toggleMode = () => setIsLogin(!isLogin);

  return (
    <View style={styles.container}>
      <TextInput
        placeholder="Email"
        value={email}
        onChangeText={setEmail}
        autoCapitalize="none"
        keyboardType="email-address"
        style={styles.input}
      />

      <TextInput
        placeholder="Password"
        value={password}
        onChangeText={setPassword}
        secureTextEntry
        style={styles.input}
      />

      <TouchableOpacity style={[styles.button, styles.buttonSpaced]} onPress={handleSubmit}>
        <Text style={styles.buttonText}>{isLogin ? 'Login' : 'Sign Up'}</Text>
      </TouchableOpacity>

      {isLogin && (
        <TouchableOpacity style={styles.switch} onPress={handleResetPassword}>
          <Text style={styles.switchText}>Forgot Password?</Text>
        </TouchableOpacity>
      )}

      <TouchableOpacity style={styles.switch} onPress={toggleMode}>
        <Text style={styles.switchText}>{`Switch to ${isLogin ? 'Sign Up' : 'Login'}`}</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 30,
    width: '100%',
  },
  input: {
    flex: 1,
    backgroundColor: '#444654',
    color: '#D1D5DA',
    paddingHorizontal: 15,
    minHeight: 45,
    borderRadius: 5,
    outlineColor: '#fff',
    width: '100%',
    marginBottom: 20,
  },
  button: {
    backgroundColor: '#7892c2',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    borderRadius: 5,
  },
  buttonSpaced: {
    marginBottom: 20,
  },
  buttonText: {
    color: '#fff',
    fontSize: 17,
    fontWeight: 'bold',
  },
  switch: {
    backgroundColor: 'rgba(0,0,0,0)',
    justifyContent: 'center',
    alignItems: 'center',
    height: 25,
    marginBottom: 10,
  },
  switchText: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
  },
});

export default AuthForm;
