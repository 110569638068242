import React, { useState, useEffect, useContext, createContext } from 'react';
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';

import { auth } from '../firebase';

const AuthContext = createContext();

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signUp = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        console.log('response', response);
        setUser(response.user);
        return response.user;
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password).then((response) => {
      setUser(response.user);
      return response.user;
    });
  };

  const signOutUser = () => {
    return signOut(auth).then(() => {
      setUser(null);
    });
  };

  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return {
    user,
    resetPassword,
    signUp,
    signIn,
    signOut: signOutUser,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};
