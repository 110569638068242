import React from 'react';

import Main from './Main';
import { ProvideAuth } from './hooks/useAuth';

export default function App() {
  return (
    <ProvideAuth>
      <Main />
    </ProvideAuth>
  );
}
